import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const DynamicImage = props => {
  const image = props.image
  const src = getImage(image)
  let objectFit = props.objectFit
  if(!objectFit) {
    objectFit = 'cover'
  }
  let title = image?.title
  if(!title) {
    title="株式会社アクア"
  }
  return <GatsbyImage image={src} alt={title} objectFit={objectFit} />
}

DynamicImage.propTypes = {
  image: PropTypes.any.isRequired,
  objectFit: PropTypes.string,
  title: PropTypes.string
}

export default DynamicImage
